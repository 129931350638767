import React from 'react';
import OrderCard from "../components/OrderCard";

export default () =>

<div>
    <h1>Component Palyground</h1>
    <OrderCard
     orderTitle="Economics for South African Students"
     orderID="4544545454545"
     orderStatus="Recieved"
     orderDate="3 hr ago"
    />
</div>
                
